
<template>
  <div>
    <iframe class="iframe" src="/im/index.html"></iframe>
  </div>
</template>




// <script>

export default {
  name: "im",
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.iframe{
    width: 1188px;
    margin: 20px auto;
    height: 100vh;
}
</style>